@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentChannelIcon {
  height: 100%;
  width: 100%;
  position: absolute;

  &.channelLIVECHAT {
    @include icons.ico("black-livechat");
  }

  &.channelLIVECHATBlack {
    @include icons.ico("black-livechat");
  }

  &.channelTWITTER {
    @include icons.channels("twitter");
  }

  &.channelTWITTERBlack {
    @include icons.channels("twitter-black");
  }

  &.channelFACEBOOK {
    @include icons.channels("facebook");
  }

  &.channelFACEBOOKBlack {
    @include icons.channels("facebook-black");
  }

  &.channelINSTAGRAM {
    @include icons.channels("instagram");
  }

  &.channelINSTAGRAMBlack {
    @include icons.channels("facebook-black");
  }

  &.channelMAIL {
    @include icons.channels("mail");
  }

  &.channelMAILBlack {
    @include icons.channels("mail-black");
  }

}