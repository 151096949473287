@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.globalPromptConfirmation {
  margin-top: 15px;

  .description {
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .button {
      height: 20px;
      padding: 10px;
      border-radius: 35px;
      font-size: 14px;
      width: calc(50% - 25px);
      position: relative;

      @include positioning.flex-globally;
      @include texts.font("semi-bold");

      svg {
        height: 22px;
        width: 22px;

        circle {
          stroke: var(--white);
        }
      }

      &.cancel {
        @include buttons.grey;
      }

      &.continue {
        @include buttons.green;
        background: var(--black) !important;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

}