@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.visualModal {
  background: #00000045;
  z-index: 9999999;

  @include positioning.screen-fixed;
  @include positioning.flex-globally;

  .box {
    width: 400px;
    overflow: hidden;
    padding: 20px;
    height: calc(100% - 40px);
    position: relative;
    background: var(--white);
    border-radius: 14px;
    box-shadow: unset !important;

    @include boxs.closable;

    .modalHead {
      display: flex;

      .name {
        font-size: 18px;

        @include texts.font("bold");
        @extend .name;
      }

      .round {
        height: 28px;
        width: 28px;
        margin-top: -2px;
        margin-left: auto;

        @include buttons.round;
        @include positioning.flex-globally;

        &.CloseButton {
          visibility: visible;
        }

        .icon {
          height: 12px;
          width: 12px;

          @include icons.ico("black-close");
        }
      }
    }

    .modalContent {
      position: relative;
      height: calc(100% - 26px);
    }
  }

  @media screen and (max-width: 480px) {
    .box {
      width: calc(100% - 40px) !important;
      position: fixed;
      padding: 20px !important;
      bottom: 0;
      border-radius: 14px 14px 0px 0px !important;
    }
  }
}